import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import useSWR from 'swr'

import { PrestakeEvent, PrestakeEventBn } from '../constants/lyraSnapshot'
import useAuth from './useAuth'

const EMPTY: PrestakeEventBn[] = []

const fetcher = async (): Promise<PrestakeEventBn[]> => {
  const res = await fetch('/api/prestake')
  const prestakeRes = (await res.json()) as PrestakeEvent[]
  return prestakeRes.map((e) => ({
    ...e,
    amount: toBigNumber(e.amount, WEI_DECIMALS),
  })) as PrestakeEventBn[]
}

export default function usePrestakeBalance() {
  const { user } = useAuth()
  const { data, ...swr } = useSWR(user ? ['PrestakeBalance'] : null, fetcher, {
    keepPreviousData: true,
  })
  return {
    data: data ?? EMPTY,
    ...swr,
  }
}
