import Button from '@lyra/core/components/Button'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import BigNumberInput from '@lyra/core/components/Input/BigNumberInput'
import Section from '@lyra/core/components/Section'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import formatNumber from '@lyra/core/utils/formatNumber'
import { TransactionOptions } from '@lyra/web/constants/transactions'
import useMigrationBalances from '@lyra/web/hooks/useMigrationBalances'
import usePrestakeBalance from '@lyra/web/hooks/usePrestakeBalance'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { useCallback, useMemo, useState } from 'react'
import { YStack } from 'tamagui'

import TransactionButtonRow from '../common/TransactionButtonRow'

export default function MigrationPrestakeSection() {
  const { data: migrationBalances, isLoading: isMigrationBalancesLoading } = useMigrationBalances()
  const {
    data: prestakeBalances,
    isLoading: isPrestakeBalancesLoading,
    mutate: mutatePrestakeBalances,
  } = usePrestakeBalance()

  const { prestake } = useTransaction()

  const [prestakeInputAmount, setPrestakeInputAmount] = useState<bigint>(BigInt(0))

  const totalLyraBalance = useMemo(
    () => (migrationBalances ? migrationBalances.totalBalance : BigInt(0)),
    [migrationBalances]
  )
  const totalLyraBalanceNum = bigNumberToNumberUNSAFE(totalLyraBalance, WEI_DECIMALS)

  const prestakedBalance = useMemo(() => {
    // If user has max prestaked, then just return total LYRA balance
    if (prestakeBalances.some((p) => p.isMaxPrestake)) {
      return totalLyraBalance
    }
    return prestakeBalances.reduce(
      (total, prestakeBalance) => total + prestakeBalance.amount,
      BigInt(0)
    )
  }, [prestakeBalances, totalLyraBalance])

  const prestakedBalanceNum = bigNumberToNumberUNSAFE(prestakedBalance, WEI_DECIMALS)
  const maxPrestakeAmount = useMemo(
    () => totalLyraBalance - prestakedBalance,
    [prestakedBalance, totalLyraBalance]
  )

  const bonus = useMemo(() => prestakedBalanceNum * 0.025, [prestakedBalanceNum])
  const isLoading = isPrestakeBalancesLoading || isMigrationBalancesLoading

  const handlePressPrestake = useCallback(
    async (options: TransactionOptions) => {
      const isMaxPrestake = maxPrestakeAmount === prestakeInputAmount
      await prestake(prestakeInputAmount, isMaxPrestake, options)
      await mutatePrestakeBalances()
      setPrestakeInputAmount(BigInt(0))
    },
    [maxPrestakeAmount, mutatePrestakeBalances, prestake, prestakeInputAmount]
  )

  return (
    <Section isLarge>
      <Section.Header
        title="Prestake Bonus"
        subtitle="Holders who prestake DRV into the airdrop will earn a 2.5% one-time bonus on their DRV balance."
      />
      <Section.Grid maxColWidth={160} minColWidth={140}>
        <DataWithLabel
          label="Balance"
          value={isLoading ? '...' : `${formatNumber(totalLyraBalanceNum)} LYRA`}
        />
        <DataWithLabel
          label="Prestaked"
          value={
            isLoading
              ? '...'
              : `${formatNumber(prestakedBalanceNum)} / ${formatNumber(totalLyraBalanceNum)} DRV`
          }
        />
        <DataWithLabel
          color="cta"
          label="Bonus"
          value={isLoading ? '...' : `${formatNumber(bonus)} DRV`}
        />
      </Section.Grid>
      <Section.XStack>
        <YStack gap="$1" width={320}>
          <BigNumberInput
            decimals={WEI_DECIMALS}
            size="lg"
            defaultValue={BigInt(0)}
            formatValue={(v) => `${formatNumber(bigNumberToNumberUNSAFE(v, WEI_DECIMALS))} DRV`}
            value={prestakeInputAmount}
            onChangeValue={(val) => setPrestakeInputAmount(val)}
            rightContent={
              <Button
                size="sm"
                isTransparent
                label="Max"
                onPress={() => setPrestakeInputAmount(maxPrestakeAmount)}
              />
            }
          />
          <TransactionButtonRow
            padding={0}
            skipGeoblockCheckDONOTUSE
            loggerAction="prestake-drv"
            reviewTitle="Prestake DRV"
            isDisabled={prestakeInputAmount === BigInt(0)}
            label="Prestake"
            onSubmitTransaction={(options) => handlePressPrestake(options)}
          />
        </YStack>
      </Section.XStack>
    </Section>
  )
}
