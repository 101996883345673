import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Section from '@lyra/core/components/Section'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import formatNumber from '@lyra/core/utils/formatNumber'
import formatPercentage from '@lyra/core/utils/formatPercentage'
import useHolderPoints from '@lyra/web/hooks/useHolderPoints'
import useMigrationBalances from '@lyra/web/hooks/useMigrationBalances'

export default function MigrationHolderPointsSection() {
  const { data: migrationBalances, isLoading: isMigrationBalancesLoading } = useMigrationBalances()
  const { balance: holderPointsBalance, isLoading: isHolderPointsLoading } = useHolderPoints()

  return (
    <Section isLarge width="100%">
      <Section.Header
        title="Holder Bonus"
        subtitle="Holders who staked LYRA into the snapshot earned 1 holder point (HP) per staked LYRA per hour. Holder points will convert pro-rata to DRV."
      />
      <Section.Grid maxColWidth={160} minColWidth={140}>
        <DataWithLabel
          label="Balance"
          value={
            isMigrationBalancesLoading
              ? '...'
              : `${formatNumber(
                  bigNumberToNumberUNSAFE(migrationBalances.totalStkLyraBalance, WEI_DECIMALS),
                  {
                    maxDps: 2,
                  }
                )} stkLYRA`
          }
        />
        <DataWithLabel
          label="Holder Points"
          value={isHolderPointsLoading ? '...' : formatNumber(holderPointsBalance, { maxDps: 2 })}
        />
        <DataWithLabel
          label="Share"
          value={
            isMigrationBalancesLoading
              ? '...'
              : formatPercentage(migrationBalances.holderPoints.share)
          }
        />
      </Section.Grid>
    </Section>
  )
}
