'use client'

import BodyText from '@lyra/core/components/BodyText'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Section from '@lyra/core/components/Section'
import TooltipText from '@lyra/core/components/TooltipText'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import formatNumber from '@lyra/core/utils/formatNumber'
import useMigrationBalances from '@lyra/web/hooks/useMigrationBalances'
import React, { useMemo } from 'react'
import { XStack, YStack } from 'tamagui'

export default function MigrationLyraBalancesSection() {
  const { data: migrationBalances, isLoading } = useMigrationBalances()

  const totalLyraBalanceNum = useMemo(
    () =>
      migrationBalances ? bigNumberToNumberUNSAFE(migrationBalances.totalBalance, WEI_DECIMALS) : 0,
    [migrationBalances]
  )

  return (
    <Section isLarge width="100%">
      <Section.Header
        title="LYRA to DRV"
        subtitle="LYRA will be migrated 1:1 to DRV with the same total supply"
      />
      <Section.Grid maxColWidth={160} minColWidth={140}>
        <DataWithLabel
          label="Balance"
          value={
            isLoading ? (
              '...'
            ) : totalLyraBalanceNum > 0 ? (
              <TooltipText
                size="lg"
                color="primary"
                label={`${formatNumber(totalLyraBalanceNum)} LYRA`}
                stayOpenOnHover
                tooltipTitle="Balances"
                tooltipContent={
                  <YStack gap="$3" maxHeight={300} overflow="scroll">
                    {Object.entries(migrationBalances.networkBalances).map(
                      ([chain, balanceOnChain]) => (
                        <YStack key={chain} gap="$1">
                          <BodyText color="secondary">{chain}</BodyText>
                          {balanceOnChain.protocols.map((protocol, idx) => (
                            <XStack key={idx} gap="$2" justifyContent="space-between" width="100%">
                              <BodyText maxWidth={180} textTransform="capitalize">
                                {protocol.description.replace('Lyra', 'LYRA')}
                              </BodyText>
                              <BodyText>
                                {formatNumber(
                                  bigNumberToNumberUNSAFE(protocol.balance, WEI_DECIMALS)
                                )}{' '}
                                {protocol.token.replace('Lyra', 'LYRA')}
                              </BodyText>
                            </XStack>
                          ))}
                        </YStack>
                      )
                    )}
                  </YStack>
                }
              />
            ) : (
              <BodyText>0 LYRA</BodyText>
            )
          }
        />
        <DataWithLabel
          label="Converts To"
          value={
            isLoading ? (
              '...'
            ) : (
              <BodyText size="lg" color="cta">
                {formatNumber(totalLyraBalanceNum)} DRV
              </BodyText>
            )
          }
        />
      </Section.Grid>
    </Section>
  )
}
