import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { useEffect, useMemo, useState } from 'react'

import { MIGRATION_SNAPSHOT_TIMESTAMP_SEC } from '../constants/lyraSnapshot'
import useAuth from './useAuth'
import useMigrationBalances from './useMigrationBalances'
import useOrderbookTimestamp from './useOrderbookTimestamp'

const getCurrentHolderPoints = (
  balance: number,
  pointsRate: number,
  lastUpdateTimestamp: number,
  getTimestamp: () => number
) => {
  const currentTimestamp = Math.floor(getTimestamp() / 1000)
  const numIntervals = currentTimestamp - lastUpdateTimestamp
  const pointsSinceUpdate = numIntervals * pointsRate
  return {
    balance: balance + pointsSinceUpdate,
    lastUpdateTimestamp: currentTimestamp,
  }
}

const DEFAULT_BALANCE = {
  balance: undefined,
  lastUpdateTimestamp: MIGRATION_SNAPSHOT_TIMESTAMP_SEC,
}

export default function useHolderPoints() {
  const { user } = useAuth()
  const { getTimestamp } = useOrderbookTimestamp()
  const { data: migrationBalances, isLoading } = useMigrationBalances()

  // 1 points per second per stkLYRA
  const holderPointsRatePerSecond = useMemo(() => {
    const pointsRatePerHour = migrationBalances.totalStkLyraBalance
    return bigNumberToNumberUNSAFE(pointsRatePerHour / BigInt(3600), WEI_DECIMALS)
  }, [migrationBalances])

  const [optimisticHolderPoints, setOptimisticHolderPoints] = useState<{
    balance: number | undefined
    lastUpdateTimestamp: number
  }>(DEFAULT_BALANCE)

  useEffect(() => {
    const tickPoints = () => {
      setOptimisticHolderPoints((currentPoints) =>
        getCurrentHolderPoints(
          currentPoints.balance ? currentPoints.balance : migrationBalances.holderPoints.balance,
          holderPointsRatePerSecond,
          currentPoints.lastUpdateTimestamp,
          getTimestamp
        )
      )
    }
    let i: NodeJS.Timeout
    if (!isLoading) {
      tickPoints()
      i = setInterval(() => tickPoints(), 1000)
    }
    return () => {
      clearInterval(i)
    }
  }, [getTimestamp, holderPointsRatePerSecond, migrationBalances.holderPoints.balance, isLoading])

  useEffect(() => {
    // Reset on sign out
    if (!user) {
      setOptimisticHolderPoints(DEFAULT_BALANCE)
    }
  }, [user])

  return {
    balance: optimisticHolderPoints.balance ?? 0,
    isLoading,
  }
}
