import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import useSWR from 'swr'
import { Address } from 'viem'

import {
  HolderPointsRouteResponse,
  ProtocolBalanceBn,
  UserBalanceResponseBn,
} from '../constants/lyraSnapshot'
import useAuth from './useAuth'

type NetworkMigrationBalance = {
  [chain: string]: {
    totalBalance: bigint
    protocols: ProtocolBalanceBn[]
  }
}

type UserMigrationBalances = {
  holderPoints: HolderPointsRouteResponse
  totalBalance: bigint
  totalStkLyraBalance: bigint
  networkBalances: NetworkMigrationBalance
}

const EMPTY_MIGRATION_BALANCE: UserMigrationBalances = {
  holderPoints: {
    balance: 0,
    share: 0,
    totalPoints: 0,
  },
  totalBalance: BigInt(0),
  totalStkLyraBalance: BigInt(0),
  networkBalances: {},
}

const fetchUserBalances = async (): Promise<UserMigrationBalances | null> => {
  const response = await fetch('/api/drv-balances', {
    method: 'GET',
  })
  if (!response.ok) {
    return null
  }
  const data = await response.json()

  const userBalanceData: UserBalanceResponseBn = {
    holderPoints: data.holderPoints,
    totalBalance: toBigNumber(data.totalBalance, WEI_DECIMALS),
    protocolBalances: data.protocolBalances.map((pb: ProtocolBalanceBn) => ({
      protocol: pb.protocol,
      chain: pb.chain,
      balance: toBigNumber(pb.balance, WEI_DECIMALS),
      token: pb.token,
      description: pb.description,
    })),
  }

  const networkBalances = userBalanceData.protocolBalances.reduce((acc, pb) => {
    const chain = pb.chain
    if (!acc[chain]) {
      acc[chain] = {
        totalBalance: BigInt(0),
        protocols: [],
      }
    }
    acc[chain].totalBalance += pb.balance
    acc[chain].protocols.push(pb)
    return acc
  }, {} as NetworkMigrationBalance)

  const totalStkLyraBalance = userBalanceData.protocolBalances
    .filter((pb) => pb.token === 'stkLyra' || pb.token === 'sinkLyra')
    .reduce((sum, pb) => sum + pb.balance, BigInt(0))

  return {
    holderPoints: userBalanceData.holderPoints,
    totalBalance: userBalanceData.totalBalance,
    totalStkLyraBalance,
    networkBalances,
  }
}

export default function useMigrationBalances() {
  const { user } = useAuth()
  const ownerAddress = user?.ownerAddress?.toLowerCase() as Address | undefined
  const { data, error, isLoading, mutate } = useSWR(
    ownerAddress ? 'MigrationBalances' : null,
    fetchUserBalances
  )

  return {
    data: data ?? EMPTY_MIGRATION_BALANCE,
    isLoading,
    isError: !!error,
    mutate,
  }
}
