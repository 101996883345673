'use client'

import Link from '@lyra/core/components/Link'
import Section from '@lyra/core/components/Section'
import MigrationHolderPointsSection from '@lyra/web/containers/migration/MigrationHolderPointsSection'
import MigrationLyraBalancesSection from '@lyra/web/containers/migration/MigrationLyraBalancesSection'
import MigrationPrestakeSection from '@lyra/web/containers/migration/MigrationPrestakeSection'

import { HELP_LYRA_MIGRAION_URL } from '../../constants/urls'

export default function MigrationPageHelper() {
  return (
    <>
      <Section isLarge noTopBorder width="100%">
        <Section.Header
          title="LYRA to DRV Migration"
          subtitle={
            <>
              The LYRA snapshot was taken on May 8 12:00am UTC. All LYRA balances will be migrated
              1:1 to DRV with the same total supply in Q4 2024. Holder Points are not fungible with
              Trading Points.&nbsp;
              <Link label="Learn more" href={HELP_LYRA_MIGRAION_URL} />
            </>
          }
        />
      </Section>
      <MigrationLyraBalancesSection />
      <MigrationPrestakeSection />
      <MigrationHolderPointsSection />
    </>
  )
}
